$snowie-pink: #ea166f;
$snowie-pink-dark: #df207c;
$snowie-purple: #902a8c;
$snowie-green: #74c045;
$snowie-blue: #24abe2;
$snowie-yellow: #fdfe36;
$snowie-blue-dark: #044a66;
$snowie-blue-secondary: #0072bc;
$snowie-orange: #f7941d;

$snowie-blue-transparent: rgba(40, 172, 223, 0.5);
$snowie-green-transparent: rgba(116, 190, 72, 0.7);

.photo-holder {
    width: 75%;
    max-width: 400px;
    min-width: 225px;
    height: 600px;
    margin: auto;
}

.menu-holder {
    width: 75%;
    max-width: 400px;
    min-width: 225px;
    height: auto;
    max-height: 600px;
    min-height: 400px;
    margin: auto;
}

.swiper {
    width: 100%;
    min-width: 300px;
    height: 100%;
    
  }
  
.swiper-slide {
    text-align: center;
    font-size: 18px;
    margin: auto;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-card {
    height: 95%;
    width: 85%;
    margin: auto;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    z-index: 97;
    background-color: $snowie-blue-transparent;
    box-shadow: 5px 5px 5px 2px $snowie-blue-secondary;

    img {
        height: 70%;
        width: auto;
        border-radius: 25px;
    }

    .card-info {
        height: 25%;
    }
     
    span {
        text-shadow: $snowie-blue 1px 1px 5px;
        margin-top: 5px;
        font-weight: 900;
        font-size: 20px;
        font-family: sans-serif;
        letter-spacing: 3px;
    }

    p {
        color: $snowie-blue-dark;
        font-size: 14px;
        font-weight: 900;
        width: 85%;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin: auto;
    }
  }

.photos-page {
    display: flex;
    height: 100vh;
    width: 85%;
    top: 0;

    .text-zone {
        position: absolute;
        left: 13%;
        top: 10%;
        width: 90%;
        height: 85%;
        max-height: 90%;
        margin-left: 0;
        display: inline-block;
        z-index: 99 !important;

        hr {
            width: 90%;
            margin: 5px;
        }
    }

    h1 {
        position: relative;
        color: #fff;
        text-align: center;
        filter: drop-shadow(0px 0px 4px black);
        width: 80%;
        font-size: 28px;
        margin: auto;
        font-family: 'Coolvetica';
        font-weight: 400;

        &::before {
            font-family: 'La Belle Aurore';
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }

        &::after {
            font-family: 'La Belle Aurore';
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: 18px;
            left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }
    }

    h2 {
        color: white;
        text-shadow: $snowie-pink 1px 1px 5px;
        margin-top: 10px;
        font-weight: 900;
        font-size: 20px;
        font-family: sans-serif;
        letter-spacing: 3px;
    }

    .flat-button {
        color: $snowie-green;
        text-shadow: black 1px 1px 3px;
        font-size: 16px;
        font-weight: 900;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        background-color: $snowie-blue-transparent;
        border: 2px solid $snowie-green;
        border-radius: 5px;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background-color: $snowie-green-transparent;
            color: black;
            text-shadow: none;
        }
    }
}

.tabs-holder {
    width: 100%;
    min-width: 300px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    margin: 0;
    padding: 0;
}

.tabs {
    display: flex;
    flex-direction: column;
    width: 100%;

    align-items: center;
    // justify-content: center;
    // overflow: hidden;

    min-height: 400px;
    margin: 0;
    padding: 5px;
    color: $snowie-blue-dark;
    border-radius: 2rem;
    @media (max-width: 769px) {
     padding: 2rem 0;
    }

    .nav-holder {
        width: auto;
        height: auto;
        margin: 0px;
        // margin: 3.5rem auto 1.5rem;

        .tabnav {
            width: 100%;
            display: flex;
            justify-content: space-between;

            border-radius: 2rem;
            padding: 0;
            margin: auto;
            
        
            .tab {
                width: 50%;
                padding: 1rem;
                list-style: none;
                text-align: center;
                cursor: pointer;
                transition: all 0.7s;
                border-bottom-left-radius: 2rem;
                border-top-left-radius: 2rem;
                background: $snowie-blue-secondary;
                color: white;
                font-size: 1.5rem;
                font-weight: 900;
            }
        
            .tab:nth-child(2) {
            border-radius: 0;
            border-bottom-right-radius: 2rem;
            border-top-right-radius: 2rem;
            }
        
            .tab:hover {
                background: $snowie-blue-transparent;
                color: $snowie-blue-dark;
            }
        
            .active {
                background: $snowie-green;
                color: $snowie-blue-dark;
              }
        }

    }

    .outlet {
        width: 100%;
        min-width: 250px;
        height: auto;
        min-height: 400px;
        margin: auto;
        display: flex;

        img {
            width: 100%;
            min-width: 200px;
            height: auto;
            border-radius: 25px;
            box-shadow: 5px 5px 5px 2px $snowie-blue-secondary;
        }
    }
}