$snowie-pink: #ea166f;
$snowie-pink-dark: #df207c;
$snowie-purple: #902a8c;
$snowie-green: #74c045;
$snowie-blue: #24abe2;
$snowie-yellow: #fdfe36;
$snowie-blue-dark: #044a66;
$snowie-blue-secondary: #0072bc;
$snowie-orange: #f7941d;

$snowie-blue-transparent: rgba(40, 172, 223, 0.5);
$snowie-green-transparent: rgba(116, 190, 72, 0.7);

.bus-container {
    z-index: 1 !important;
    width: 60%;
    height: auto;
    position: absolute;
    right: 5%;
    bottom: 0;
    left: auto;
    margin: auto;
    opacity: 1;
    display: inline-block;

    @media (max-width: 900px) {
        max-width: 60%;
    }

    @media (max-width: 600px) {
        min-width: 80%;
        bottom: 7%;
    }

    svg {
        width: 100%;
        height: auto;
    }

    .solid-bus {
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 100%;
        opacity: 0;
        z-index: 1;
    }
}

.svg-container {
    stroke: $snowie-blue-secondary;
    stroke-width: 10px;
}