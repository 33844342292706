$snowie-pink: #ea166f;
$snowie-pink-dark: #df207c;
$snowie-purple: #902a8c;
$snowie-green: #74c045;
$snowie-blue: #24abe2;
$snowie-yellow: #fdfe36;
$snowie-blue-dark: #044a66;
$snowie-blue-secondary: #0072bc;
$snowie-orange: #f7941d;

$snowie-blue-transparent: rgba(40, 172, 223, 0.5);
$snowie-green-transparent: rgba(116, 190, 72, 0.7);

.home-page {
    display: flex;
    height: 100vh;
    top: 0;

    .text-zone {
        position: absolute;
        left: 13%;
        top: 10%;
        width: 90%;
        max-height: 90%;
        margin-left: 20px;
        display: inline-block;
        z-index: 99 !important;
    }

    h1 {
        position: relative;
        color: #fff;
        text-align: left;
        filter: drop-shadow(0px 0px 4px black);
        width: 250px;
        font-size: 28px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        &::before {
            font-family: 'La Belle Aurore';
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }

        &::after {
            font-family: 'La Belle Aurore';
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: 18px;
            left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }
    }

    h2 {
        color: white;
        text-shadow: $snowie-pink 1px 1px 5px;
        margin-top: 10px;
        font-weight: 900;
        font-size: 20px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .menu-button {
        color: $snowie-green !important;
        margin-left: 5px;
    }

    .flat-button {
        color: $snowie-yellow;
        text-shadow: black 1px 1px 3px;
        font-size: 16px;
        font-weight: 900;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        background-color: $snowie-blue-transparent;
        border: 2px solid $snowie-green;
        border-radius: 5px;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background-color: $snowie-green-transparent;
            color: black !important;
            text-shadow: none;
        }
    }
}

#snowie-logo {
    width: 100%;
    margin: 0px;
    animation: rubberBand 1s;
    animation-delay: 1s;
}

#sherman {
    width: 65%;
    margin-right: 0px;
    margin-left: auto;
    margin-top: 0px;
}