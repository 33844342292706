:root {
  --snowie-pink: #ef0f70;
  --snowie-pink-dark: #df207c;
  --snowie-purple: #933e9c;
  --snowie-green: #75be48;
  --snowie-blue: #28acdf;
  --snowie-blue-dark: #147ac2;
}

html {
  font-size: 62.5%;
}

body {
  margin-top: 10%;
  height: 90vh;
  bottom: 0;
  font: 300 11px/1.4 'Helvetica Neue', 'sans-serif';
  color: white;
  background: linear-gradient(to bottom, var(--snowie-blue-dark), var(--snowie-blue));
  background-size: 120% 120%;
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

