$snowie-pink: #ea166f;
$snowie-pink-dark: #df207c;
$snowie-purple: #902a8c;
$snowie-green: #74c045;
$snowie-blue: #24abe2;
$snowie-yellow: #fdfe36;
$snowie-blue-dark: #044a66;
$snowie-blue-secondary: #0072bc;
$snowie-orange: #f7941d;
$snowie-blue-transparent: rgba(40, 172, 223, 0.5);

::placeholder {
    color: $snowie-yellow;
}

.contact-page {
    .text-zone {
        top: 0 !important;
    }
}

.contact-form {
    width: 100%;
    height: 300px;



    ul {
        bottom: 0 !important;
        clear: both;
        position: relative;
        li {
            padding: 0;
            border-radius: 25px;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }

        li.half {
            width: 49%;
            margin-left: 2%;
            display: inline-block;
            clear: none;

            &:first-child {
                margin-left: 0;
            }
        }

        li.phone-number {
            width: 100%;
            margin-left: 0%;
            text-align: center;
            display: inline-block;
            clear: none;
            .form-control {
                margin-left: 10% !important;
            }
        }
    }

    input[type='text'],
    input[type='email'],
    input[type='tel'],
    select {
        width: 100%;
        border: 0;
        background: $snowie-blue-secondary;
        height: 50px;
        font-size: 16px;
        color: #fff;
        padding: 0 20px;
        box-sizing: border-box;

        &:focus {
            outline: 0;
        }
    }

    textarea {
        width: 100%;
        background: $snowie-blue-secondary;
        height: 50px;
        font-size: 16px;
        color: #fff;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;

        &:focus {
            outline: 0;
        }
    }

    .flat-button {
        color: $snowie-yellow;
        background: 0 0;
        font: 11px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 8px 10px;
        border: 2px solid $snowie-yellow;
        border-radius: 15px;

    }
}