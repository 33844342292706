@import './mixins';

$snowie-pink: #ea166f;
$snowie-pink-dark: #df207c;
$snowie-purple: #902a8c;
$snowie-green: #74c045;
$snowie-blue: #24abe2;
$snowie-yellow: #fdfe36;
$snowie-blue-dark: #044a66;
$snowie-blue-secondary: #0072bc;
$snowie-orange: #f7941d;

// .stop1 {
//     stop-color: $snowie-blue-dark;
// }

// .stop2 {
//     stop-color: $snowie-blue;
// }

// .stop3 {
//     stop-color: $snowie-blue-dark;
// }

// .cup {
//     z-index: 998 !important;   
//     width: 50%;
//     height: 75%;
//     left: 50%;
//     top: 50%;
//     position: absolute;
//     transform: rotate(-45deg);
//     overflow: hidden;
//     svg {
//         height: 100%;
//         fill: url('#Gradient');
//         filter: drop-shadow(0px 0px 1px black);
//     }
// }
  
// .scoop {
//     z-index: 996;
//     width: 86px;
//     height: 86px;
//     border-radius: 50%;
//     position: absolute;
//     bottom: 20px; /* move it up slightly to fit better in the cone */
//     left: 12%;
//     top: 33%;
//     transform: rotate(17deg);
// }

// .white {
//     z-index: 997;
//     background: white;
//     animation: fadeOut 4s 0s;
//     animation-fill-mode: forwards;
// }

.rainbow-wave {
    position: relative;
    display: inline-block;
    background: linear-gradient(135deg, white, $snowie-orange, $snowie-pink, $snowie-purple, $snowie-blue, $snowie-yellow);
    background-size: 400% 400%;
    animation: rainbow-waves 8s ease-in-out infinite;

    &::before {
        content: "";
        position: absolute;
        top: -5px;
        left: -5px;
        right: -5px;
        bottom: -5px;
        border-radius: 50%;
        z-index: -1;
        animation: rainbow-wave 2s ease-in-out infinite;
    }
}

@keyframes rainbow-waves {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

@keyframes ball-bounce {
    33% {
        transform: translateY(10px);
        
    }
    66% {
        transform: translateY(-10px);

    }
    100% {
        transform: translateY(0);
        background-position: 0% -150%;
    }
}

.ball1 {
    background: $snowie-pink;
    background: linear-gradient(0deg, $snowie-pink 0%, $snowie-orange 20%, $snowie-yellow 40%, $snowie-green 60%, $snowie-blue 80%, $snowie-purple 100%);
    background-size: 400% 200%;
    animation: ball-bounce 0.6s -0.21s infinite ease-in-out;
}

.ball2 {
    background: $snowie-pink;
    background: linear-gradient(0deg, $snowie-pink 0%, $snowie-orange 20%, $snowie-yellow 40%, $snowie-green 60%, $snowie-blue 80%, $snowie-purple 100%);
    background-size: 400% 200%;
    animation: ball-bounce 0.6s -0.14s infinite ease-in-out;
}

.ball3 {
    background: $snowie-pink;
    background: linear-gradient(0deg, $snowie-pink 0%, $snowie-orange 20%, $snowie-yellow 40%, $snowie-green 60%, $snowie-blue 80%, $snowie-purple 100%);
    background-size: 400% 200%;
    animation: ball-bounce 0.6s -0.07s infinite ease-in-out;
}

.ball-bounce {
    position: relative;
    height: 30px;
    width: 100px;
    margin: 40% auto;

    > div {
        @include balls();
        @include global-animation();

        display: inline-block;
    }
}