$snowie-pink: #ea166f;
$snowie-pink-dark: #df207c;
$snowie-purple: #902a8c;
$snowie-green: #74c045;
$snowie-blue: #24abe2;
$snowie-yellow: #fdfe36;
$snowie-blue-dark: #044a66;
$snowie-blue-secondary: #0072bc;
$snowie-orange: #f7941d;

.navbar {
    background: $snowie-blue-secondary;
    border-right-style: solid;
    border-width: 0.5px;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 2.5px white);
    width: 12%;
    max-width: 90px;
    height: 90%;
    position: absolute;
    top: 5%;
    z-index: 3;
    min-height: 500px;

    .tags {
        color: $snowie-yellow;
        filter: drop-shadow(0px 0px 2px black);
        opacity: 0.9;
        display: block;
        font-size: 16px;
        font-family: 'La Belle Aurore';
        padding: 8px 0 0 0;
        margin: 0;
        text-align: center;
    }

    .logo {
        display: block;
        padding: 8px 0;

        img {
            display: block;
            margin: 8px auto;
            width: 75%;
            height: auto;
            filter: drop-shadow(0px 0px 2px black);

            &.sub-logo {
                width: 50px;
            }
        }
    }
}

nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 100%;
    min-height: 200px;
    margin-top: 25px;
    width: 100%;

    a {
        font-size: 22px;
        font-weight: 900;

        @media (max-width: 768px) {
            font-size: 18px;
            font-weight: 700;
        }
        display: block;
        line-height: 51px;
        height: 51px;
        position: relative;
        text-decoration: none;

        i {
            transition: all 0.3s ease-out;
        }

        svg {
            color: white;
            filter: drop-shadow(0px 0px 2px black);
        }

        &:hover {
            color: $snowie-yellow;
            filter: drop-shadow(0px 0px 2px black);

            svg {
                opacity: 0;
            }

            &:after {
                opacity: 1;
            }
        }

        &:after {
            content: '';
            font-size: 12px;
            letter-spacing: 2px;
            position: absolute;
            bottom: 0;
            display: block;
            width: 100%;
            text-align: center;
            opacity: 0;
            transition: all 0.3s ease-out;
        }

        &:first-child {
            &::after {
                content: 'HOME';
            }
        }
    }

    a.about-link {
        &::after {
            content: 'ABOUT';
        }
    }

    a.contact-link {
        &::after {
            content: 'CHAT';
        }
    }

    a.photos-link {
        &::after {
            content: 'PICS';
        }
    }

    a.fb-link {
        &::after {
            content: 'FOLLOW';
        }
    }

    a.active {
        svg {
            color: $snowie-pink;
        }
    }
}

ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
        a {
            padding: 7px 0;
            display: block;
            font-size: 15px;
            line-height: 16px;
            svg {
                color: $snowie-green;
            }
            &:hover svg {
                color: $snowie-pink;
            }
        }
    }

    .fb-link {
        position: absolute !important;
        text-decoration: none;
        color: white;
        bottom: 0px;
        svg {
            color: white;
        }
        &:hover svg {
            color: $snowie-pink;
        }
    }
    
}
