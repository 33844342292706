@use "sass:math";

$snowie-pink: #ea166f;
$snowie-pink-dark: #df207c;
$snowie-purple: #902a8c;
$snowie-green: #74c045;
$snowie-blue: #24abe2;
$snowie-yellow: #fdfe36;
$snowie-blue-dark: #044a66;
$snowie-blue-secondary: #0072bc;
$snowie-orange: #f7941d;

$snowie-blue-transparent: rgba(40, 172, 223, 0.5);
$snowie-green-transparent: rgba(116, 190, 72, 0.7);

.text-animate {
    display: inline-block;
    opacity: 0;
    animation: bounceIn 0.8s 1s forwards;
    min-width: 10px;
}

.text-animate-hover {
    min-width: 10px;
    display: inline-block;
    animation-fill-mode: both;

    &:hover {
        animation: rubberBand 1s;
        color: $snowie-pink;
    }
}

@for $index from 1 through 35 {
    .text-animate._#{$index} {
        animation-delay: #{math.div($index, 10)}s;
    }
}