$snowie-pink: #ea166f;
$snowie-pink-dark: #df207c;
$snowie-purple: #902a8c;
$snowie-green: #74c045;
$snowie-blue: #24abe2;
$snowie-yellow: #fdfe36;
$snowie-blue-dark: #044a66;
$snowie-blue-secondary: #0072bc;
$snowie-orange: #f7941d;

$snowie-blue-transparent: rgba(40, 172, 223, 0.5);
$snowie-green-transparent: rgba(116, 190, 72, 0.7);

$animation-time: 32s;
$animation-delay: 2s;

.stage-cube-container {
    width: 50%;
    height: 100%;
    top: 0;
    padding-top: 18%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
    z-index: 5;

    @media (max-width: 768px) {
        transform: translateX(75px);
    }

    @media (max-width: 500px) {
        width: 100%;
        transform: translateX(20px);
        padding-top: 0;
        overflow: visible;
    }

    @media (max-width: 290px) {
        opacity: 0;
    }
}

// Dog
.dog {
    color: $snowie-yellow;
    transform: translateZ(100px);
    animation: face-1-animation;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: $animation-time;
    animation-delay: $animation-delay;
}

// Ice
.ice {
    color: $snowie-blue;
    transform: rotateY(-90deg) translateZ(100px);
    animation: face-4-animation;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: $animation-time;
    animation-delay: $animation-delay;;
}

// Cake
.cake {
    color: $snowie-pink;
    animation: face-6-animation;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: $animation-time;
    animation-delay: $animation-delay;
    transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
}

// Mug
.mug {
    color: $snowie-purple;
    transform: rotateY(180deg) rotateZ(-90deg) translateZ(100px);
    animation: face-5-animation;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: $animation-time;
    animation-delay: $animation-delay;
}

// Cone
.cone {
    color: $snowie-pink;
    transform: rotateY(90deg) translateZ(100px);
    animation: face-2-animation;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: $animation-time;
    animation-delay: $animation-delay;
}

// Handshake
.shake {
    color: $snowie-green;
    transform: rotateX(-90deg) rotateZ(90deg) translateZ(100px);
    animation: face-3-animation;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: $animation-time;
    animation-delay: $animation-delay;
}

.cube-spinner {
    animation-name: rotateCube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: $animation-time;
    animation-delay: $animation-delay;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);

    .faces {

        svg {
            display: block;
            filter: drop-shadow(0px 0px 4px black);
        }


        .cube-text {
            position: absolute;
            color: white;
            font-size: 24px;
            bottom: 5px;
            font-weight: 700;
            -webkit-text-stroke: 1.5px $snowie-blue-dark;
        }

    
    }

    div {
        position: absolute;
        width: 200px;
        height: 200px;
        background-color: rgba(255, 255, 255, 0.2);
        justify-content: center;
        font-size: 100px;
        display: flex;
        align-items: center;
        border: 2px solid $snowie-blue;
        box-shadow: 0px 0px 4px white;
        border-radius: 5%;
    }
}

@keyframes rotateCube {
    from,
    to {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg); //Dog
    }
    16% {
        transform: rotateY(-90deg); // Cone
    }
    33% {
        transform: rotateY(-90deg) rotateZ(-90deg); // Handshake
    }
    50% {
        transform: rotateY(90deg); // Ice
    }
    66% {
        transform: rotateY(-180deg) rotateZ(-90deg); // Mug
    }
    83% {
        transform: rotateX(90deg) rotateY(-90deg) rotateZ(-180deg); // Cake
    }
}

@keyframes face-1-animation {
    to {
        opacity: 1; // Dog
    }
    16% {
        opacity: 0.25; // Cone
    }
    33% {
        opacity: 0.25; // Handshake
    }
    50% {
        opacity: 0.25; // Ice
    }
    66% {
        opacity: 0.25; // Mug
    }
    83% {
        opacity: 0.25; // Cake
    }
}

@keyframes face-2-animation {
    to {
        opacity: 0.25; // Dog
    }
    16% {
        opacity: 1; // Cone
    }
    33% {
        opacity: 0.25; // Handshake
    }
    50% {
        opacity: 0.25; // Ice
    }
    66% {
        opacity: 0.25; // Mug
    }
    83% {
        opacity: 0.25; // Cake
    }
}

@keyframes face-3-animation {
    to {
        opacity: 0.25; // Dog
    }
    16% {
        opacity: 0.25; // Cone
    }
    33% {
        opacity: 1; // Handshake
    }
    50% {
        opacity: 0.25; // Ice
    }
    66% {
        opacity: 0.25; // Mug
    }
    83% {
        opacity: 0.25; // Cake
    }
}

@keyframes face-4-animation {
    to {
        opacity: 0.25; // Dog
    }
    16% {
        opacity: 0.25; // Cone
    }
    33% {
        opacity: 0.25; // Handshake
    }
    50% {
        opacity: 1; // Ice
    }
    66% {
        opacity: 0.25; // Mug
    }
    83% {
        opacity: 0.25; // Cake
    }
}

@keyframes face-5-animation {
    to {
        opacity: 0.25; // Dog
    }
    16% {
        opacity: 0.25; // Cone
    }
    33% {
        opacity: 0.25; // Handshake
    }
    50% {
        opacity: 0.25; // Ice
    }
    66% {
        opacity: 1; // Mug
    }
    83% {
        opacity: 0.25; // Cake
    }
}

@keyframes face-6-animation {
    to {
        opacity: 0.25; // Dog
    }
    16% {
        opacity: 0.25; // Cone
    }
    33% {
        opacity: 0.25; // Handshake
    }
    50% {
        opacity: 0.25; // Ice
    }
    66% {
        opacity: 0.25; // Mug
    }
    83% {
        opacity: 1; // Cake
    }
}