$snowie-pink: #ef0f70;
$snowie-yellow: #fdfe36;
$primary-color: $snowie-pink; // for Loader

@import 'animate.css';

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/assets/fonts/helvetica-neu.ttf') format('ttf');
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
}

@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

input,
textarea {
  font-family: 'Helvetica Neue';
}

.loader-holder {
  z-index: -1;
  display: block; 
  position: absolute;
  left: 0%;
  right: 0;
  margin:auto;
  top: 0;
  bottom: 0;
  width: 50%;
  height: 50%;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}