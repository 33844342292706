$snowie-pink: #ea166f;
$snowie-pink-dark: #df207c;
$snowie-purple: #902a8c;
$snowie-green: #74c045;
$snowie-blue: #24abe2;
$snowie-yellow: #fdfe36;
$snowie-blue-dark: #044a66;
$snowie-blue-secondary: #0072bc;
$snowie-orange: #f7941d;
$snowie-blue-transparent: rgba(40, 172, 223, 0.5);

.page {
    width: 97%;
    height: 100%;
    top: 0;
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
}

// .top-tags {
//     bottom: auto;
//     top: 45px;
// }

// .tags {
//     color: $snowie-yellow;
//     filter: drop-shadow(0px 0px 2px black);
//     opacity: 0.9;
//     position: absolute;
//     bottom: 0;
//     left: 120px;
//     font-size: 18px;
//     font-family: 'La Belle Aurore';
// }

// .bottom-tags {
//     margin-left: -20px;
// }

.container {
    width: 95%;
    left: 2%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}

#snow-container {
    height: 100vh;
    width: 100%;
    display: grid;
    place-items: center;
}

#snowflake{
    writing-mode: horizontal-tb;
}

.snow {
    position: fixed;
    top: -5vh;
    transform: translateY(0);
    transform : rotate(180deg);
    animation: fall 15s linear forwards;

    &:nth-child(even) {
        z-index: 1;
    }
    &:nth-child(odd) {
        z-index: 10;
    }
}

@keyframes fall {
  to {
    transform: translateY(105vh) translateX(15vw);
    opacity: 0;
  }
}

.about-page,
.contact-page {
    scroll-behavior: auto;
    .text-zone {
        position: absolute;
        left: 14%;
        top: 0;
        width: 40%;
        vertical-align: middle;
        display: table-cell;
        min-height: 75%;
        z-index: 99 !important;

        @media (max-width: 768px) {
            top: 15%;
            width: 50%;
            left: 18%;
            bottom: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        @media (max-width: 500px) {
            top: 45%;
            width: 75%;
            left: 18%;
            bottom: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        @media (max-width: 380px) {
            top: 75%;
        }

        @media (max-width: 290px) {
            top: 45%;
        }


        .cube-text-match {
            .intro {
                font-size: 14px;
            }

            h2 {
                font-size: 24px;
                font-weight: 900;
                margin-bottom: 0;
                transform: translateX(-8px);
                filter: drop-shadow(0px 0px 4px black);
                -webkit-text-stroke: 0.5px black;
                font-family: 'Coolvetica';
                letter-spacing: 2px;

                @media (max-width: 500px) {
                    text-align: center;
                }

                &:nth-of-type(1) {
                    color: $snowie-yellow;
                }
                &:nth-of-type(2) {
                    color: $snowie-pink;
                }
                &:nth-of-type(3) {
                    color: $snowie-green;
                }
                &:nth-of-type(4) {
                    color: $snowie-blue;
                }
                &:nth-of-type(5) {
                    color: $snowie-purple;
                    filter: drop-shadow(0px 0px 4px rgba(25, 255, 255, 1));
                }
                &:nth-of-type(6) {
                    color: $snowie-pink;
                    
                }
            }
        }

        h1 {
            color: #fff;
            text-align: left;
            filter: drop-shadow(0px 0px 4px black);
            width: 100%;
            font-size: 53px;
            margin: 0;
            font-family: 'Coolvetica';
            font-weight: 400;

            @media (max-width: 500px) {
                font-size: 40px;
                text-align: center;
            }
        }

        p {
            font-size: 20px;
            margin-top: 0;
            color: #fff;
            filter: drop-shadow(0px 0px 4px black);
            -webkit-text-stroke: 0.1px $snowie-blue-dark;            
            font-family: sans-serif;
            font-weight: 300;
            min-width: fit-content;
            animation: pulse 1s;
            animation-timing-function: ease-in;

            @media (max-width: 500px) {
                text-align: center;
            }

            &:nth-of-type(1) {
                animation-delay: 1.1s;
            }
            &:nth-of-type(2) {
                animation-delay: 1.2s;
            }
            h1 {
                color: #fff;
                text-align: left;
                filter: drop-shadow(0px 0px 4px black);
                width: 100%;
                font-size: 53px;
                margin: 0;
                font-family: 'Coolvetica';
                font-weight: 400;
    
                @media (max-width: 500px) {
                    font-size: 40px;
                    text-align: center;
                }
            }&:nth-of-type(3) {
                animation-delay: 1.3s;
            }
            &:nth-of-type(4) {
                animation-delay: 1.4s;
            }
            &:nth-of-type(5) {
                animation-delay: 1.5s;
            }
            &:nth-of-type(6) {
                animation-delay: 1.6s;
            }
        }
    }
}

  